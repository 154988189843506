import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Trans, { t } from "../../components/Translate";
import Layout from "../../layouts/default";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormResponse from "../../components/FormResponse";
import Cookies from "js-cookie";

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = ({ location }) => {
  const { language } = useI18next();
  const getSuccessRedirectUrl = (location) => {
    // I had issues with this function. Tried to prevent redirecting back to registration if logging in from there and had issues. Now just sending logged in users to shifts page.
    // const prevPath = location?.state?.prevPath || null;
    // const referrer = prevPath ? `${window.location.origin}${prevPath}` : "";

    // const isACReferrer = referrer.match(/annesconcierge/i);
    // const isLoginPage = window.location.href.match(
    //   /^(.*annesconcierge)(.*login)/s
    // );

    // if (isLoginPage && isACReferrer) {
    //   // if (!referrer.match("/login")) {
    //   //   console.log("sendto1", referrer);
    //   //   return referrer;
    //   // }
    //   return referrer;
    // } else if (!isLoginPage) {
    //   return window.location;
    // }

    // return window.location.origin;
    return language === "en" ? "/shifts" : `/${language}/shifts`;
  };
  return (
    <Layout metaTitle={t("LOG_IN_META_TITLE")}>
      <section className="container">
        <div className="row">
          <div className="col col-12 col-lg-6 m-auto">
            <h1 className="content-header">
              <Trans i18nKey={"LOG_IN_FORM"} />
            </h1>
            <div className="card p-3 mt-3 mb-4">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  emailAddress: "",
                  password: "",
                  //   rememberMe: "off",
                }}
                validationSchema={validationSchema}
                onSubmit={async (
                  values,
                  { setSubmitting, setStatus, setErrors }
                ) => {
                  setSubmitting(true);
                  try {
                    const result = await axios.post(
                      `${process.env.AC_API_URL}/auth/login`,
                      {
                        emailAddress: values.emailAddress,
                        password: values.password,
                      },
                      { withCredentials: true }
                    );

                    if ((result.data || {}).errors) {
                      //   console.log("errors", (result.data || {}).errors);
                      const errorMessage =
                        result?.data?.errors[0]?.message || null;

                      if (errorMessage) {
                        setStatus({ error: errorMessage });
                      }

                      return;
                    } else {
                      Cookies.set("userToken", `${result.data.token}`);
                      //   document.cookie = `token=${result.data.token}`;
                      console.log("route", getSuccessRedirectUrl(location));
                      window.location.href = getSuccessRedirectUrl(location);
                      setErrors(null);
                    }
                  } catch (err) {
                    console.log("err", err);
                    setStatus({ error: err });
                  }
                  setSubmitting(false);
                  setStatus({ isSubmitted: true });
                  setStatus({ success: true });
                }}
              >
                {(formikProps) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    submitCount,
                    status,
                  } = formikProps;

                  const formErrors = errors || {};

                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label className="mb-1">
                          <Trans i18nKey={"EMAIL"} />
                        </label>
                        <input
                          className="form-control"
                          name="emailAddress"
                          type="email"
                          value={values.emailAddress}
                          onChange={handleChange}
                          //   invalid={errorEmail}
                        />
                        <div className="text-danger">
                          {touched.emailAddress && formErrors.emailAddress}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <Trans i18nKey={"PASSWORD"} />
                        </label>
                        <input
                          className="form-control"
                          name="password"
                          type="password"
                          onChange={handleChange}
                          value={values.password}
                          // placeholder={values.lastName ? values.lastName : 'Enter a last name'}
                          //   tag={Field}
                          //   invalid={errorPassword}
                        />
                        <div className="text-danger">
                          {touched.password && formErrors.password}
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            className="form-control"
                            type="checkbox"
                            name="rememberMe"
                            className="custom-control-input"
                            id="customSwitch1"
                            onChange={(e) =>
                              setFieldValue("rememberMe", e.target.value)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                          >
                            Remember Me
                          </label>
                        </div>
                      </div> */}

                      {!status?.success && (
                        <FormResponse
                          errors={status?.error}
                          alert={
                            Object.keys(errors || {}).length > 0 &&
                            submitCount > 0
                          }
                          isVisible={
                            status?.error ||
                            status?.success ||
                            (Object.keys(errors || {}).length > 0 &&
                              submitCount > 0)
                          }
                        ></FormResponse>
                      )}

                      <button
                        type="submit"
                        loading={isSubmitting ? "true" : "false"}
                        className="w-100 mb-2 btn btn-secondary font-weight-bold"
                      >
                        {!isSubmitting && <Trans i18nKey={"LOGIN"} />}
                        {isSubmitting && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="formStatus"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                      <div className="text-center">
                        <Link to="/reset-password" className="btn btn-link">
                          <Trans i18nKey={"FORGOT_PASSWORD"} />
                        </Link>
                      </div>
                      {/* <div className="text-muted text-center">
                <p className="mb-2">or</p>
                <a href="#!" onClick={loginWithGoogle}>
                  <img src={GoogleSignIn} />
                </a>
              </div> */}
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Login;
